import React from 'react'
import styles from './Mainvisual.module.scss'
import Logo from '../images/logo.png'

const Mainvisual = () => {
  return(
    <>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className="sng-container">
            <div className="sng-rows">
              <div className="grid-12">
                <div className={styles.logo}>
                  <img src={Logo} />
                </div>
                <p className={styles.title}>MASATONAKAMURA.WORK</p>
                <p className={styles.copy}>Make good things 24/7.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Mainvisual