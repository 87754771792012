import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import styles from './index.module.scss'
import Mainvisual from "../components/Mainvisual"

const IndexPage = () => (
  <Layout>
    <Mainvisual />
    <section className={styles.wrapper}>
      <div className="sng-container">
        <div className="sng-rows">
          <div className="grid-12">
            <h3 className={styles.heading}>
              ABOUT
              <span className={styles.subHeading}>
                PROFILE
              </span>
            </h3>
            <ul className={styles.aboutInner}>
              <li className={styles.aboutTxt}>
                <span className={styles.aboutIndex}>NAME</span><br/>
                MASATO NAKAMURA
              </li>
              <li className={styles.aboutTxt}>
                <span className={styles.aboutIndex}>JOB</span><br />
                FRONTEND ENGINEER
              </li>
              <li className={styles.aboutTxt}>
                <span className={styles.aboutIndex}>PRIVATE WORKS</span><br/>
                SONG WRITING / ARRANGEMENT<br />
                RECORDING SUPPORT<br/>
                MIX &amp; MASTERING<br/>
              </li>
              <li className={styles.aboutTxt}>
                <span className={styles.aboutIndex}>SOCIAL</span><br />
                <a href="https://www.facebook.com/masato.nakamura.jpn" target="_blank" rel="noopener" className={styles.linkTxt}>FB</a> / <a href="https://twitter.com/_masatojpn" target="_blank" rel="noopener" className={styles.linkTxt}>TW</a> / <a href="https://www.instagram.com/_masatonakamura/" target="_blank" rel="noopener" className={styles.linkTxt}>IG</a> / <a href="https://dribbble.com/masatojpn" target="_blank" rel="noopener" className={styles.linkTxt}>DR</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
